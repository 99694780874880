import { navigate } from "gatsby"
import React, { useEffect } from "react"
import ImpactPage from "../impact"

interface Props {
  location: { state: { formCompleted: boolean } }
}

const ImpactThanks = (props: Props) => {
  useEffect(() => {
    if (!props.location.state?.formCompleted) {
      navigate("/impact")
    }
  }, [props])

  return <ImpactPage didSubmit />
}

/** export */
export default ImpactThanks
